<template>
  <div class="mxs-history">
    <HistoryComponent ref="editor"
                      v-if="articleId"
                      :articleId="articleId"
                      :wss="'wss://'+wss"
                      :token="accessToken"
                      :theme="$root.config.theme.includes('dark')? 'dark':'light'"
                      :url="'https://'+wss+'/v1/history'"
                      :onClose="onClose" />
  </div>
</template>

<script>
import {HistoryComponent} from '../../../../components/mxs-editor/word-viewer'
import '../../../../components/mxs-editor/style.css'
import {applyPureReactInVue} from 'veaury'
import base from "../../../../api/base";

export default {
  name: "MxsHistory",
  props: {
    articleId: {type: String, default: ''}
  },
  data() {
    return {
      wss: base.yjs
    }
  },
  computed: {
    accessToken() {
      return localStorage.getItem("mindmap_token");
    },
  },
  components: {
    HistoryComponent: applyPureReactInVue(HistoryComponent)
  },
  mounted() {
    console.log('---articleId-',this.articleId)
  },
  methods: {
    onClose() {
      this.$emit('close')
    }
  }
}
</script>

<style scoped>

</style>
