<template>
  <div class="column-left drag-inner" ref="contentRight">
    <div class="pos-absolute mind-loading d-column" v-if="loading">
      <!-- <div class="ui-loading middle"></div>
      <span>加载中...</span> -->
      <el-image
          v-if="this.$root.config.theme.includes('dark')"
          style="min-width: 400px"
          src="/pc/mind-bg-b.png"
          fit="fill"
      />
      <el-image
          v-else
          style="min-width: 400px"
          src="/pc/mind-bg.png"
          fit="fill"
      />
    </div>
    <mind-graph
        ref="map"
        mode="edit"
        v-if="mindList"
        v-model="mindList"
        :select-item="selectItem"
        :theme="this.$root.config.theme"
        :mindmap="true"
        :resize-center="false"
        :sharp-corner="true"
        :allow-change-root="true"
        :edges="edges"
        :hide-edge="false"
        :show-toolbar="true"
        :click-edge-show-label="clickEdgeShowLabel"
        :init-show-depth="this.$root.config.mindCollapse"
        :vGap="this.$root.config.mindvGap"
        :hGap="this.$root.config.mindhGap"
        :correlation-change-to-root="correlationChangeToRoot"
        :default-edge-style="this.$root.mindEdgeStyle"
        :default-edge-link-style="this.$root.mindEdgeLinkStyle"
        :default-node-style="this.$root.mindNodeStyle"
        :quadtreeType="this.$root.config.mindQuadtreeType"
        :get-nodes="getNodes"
        @change="onMindMapChange"
        :key="key"
    />
  </div>
</template>

<script>
import MindGraph from "./MindGraph";
import {MindmapEvent} from "../../../../components/antv-mindmap/mxs-mindmap.es";
import MapDialog from "./MapDialog";

export default {
  name: "index",
  props: {
    show: {type: Boolean, default: true},
    correlationChangeToRoot: {type: Boolean, default: false},
    selectItem: {type: Object, default: undefined},
    mindRootItem: {type: Object, default: undefined},
    mapData: {type: Object, default: undefined},
    isMindLocalStorage: {type: Boolean, default: false},
    mindSelect: {type: Function, default: undefined},
    changeTitleApi: {type: Function, default: undefined},
    deleteApi: {type: Function, default: undefined},
    localData: {type: Boolean, default: false},
    pEdges: {type: Array, default: () => []},
  },
  components: {
    MapDialog,
    MindGraph,
  },
  data() {
    return {
      loading: !this.localData,
      mindList: undefined,
      edges: [],
      extraRoots: [],
      key: 0,
    };
  },
  inject: [
    "mindPasteItem",
    "onBack",
    "openCurMindMap",
    "menuIconType",
    "openMapDialog",
    "transferNewTreeItem",
    "insertHtml",
    "openEditor",
    "getAiAnswer",
    "mindImgToEditor",
    "saveStatus"
  ],
  computed: {
    graph: {
      get() {
        if (this.$refs.map) {
          return this.$refs.map.graph;
        } else {
          return undefined;
        }
      },
    },
    clickEdgeShowLabel: {
      get() {
        if (this.$root.config.mindEdgeShowLabel === "true") {
          return true;
        } else {
          return false;
        }
      },
    },
  },
  watch: {
    mindRootItem(val, old) {
      if (this.show) {
        this.loadData();
      }
    },
    isMindLocalStorage(val, old) {
      if (!this.mindRootItem) {
        this.loadData();
      }
    },
    "$root.config.mindEdgeShowLabel": {
      handler(val, old) {
        if (val !== old) {
          this.key += 1;
        }
      },
    },
    "$root.config.mindhGap": {
      handler(val, old) {
        if (val !== old) {
          this.key += 1;
        }
      },
    },
    "$root.config.mindvGap": {
      handler(val, old) {
        if (val !== old) {
          this.key += 1;
        }
      },
    },
  },
  mounted() {
    if (this.show) {
      this.loadData();
    }
  },
  methods: {
    async getNodes(ids) {
      return this.$api.article.simpleArticleInfo(ids, this.isMindLocalStorage);
    },
    getGraph() {
      return this.$refs.map?.graph;
    },
    async loadData() {
      if (
          this.mindRootItem &&
          this.getGraph()?.get("data")?.id === this.mindRootItem.id
      ) {
        return;
      } else if (this.localData) {
        this.extraRoots = [];
        this.edges = this.pEdges;
        this.mindList = this.mapData;
        this.loading = false;
        return;
      }
      this.extraRoots = [];
      this.loading = true;
      this.edges = [];
      // this.mindList = undefined;
      const tempId = this.mindRootItem ? this.mindRootItem.id : undefined;
      const hasLoadAll = true;
      let res;
      if (!hasLoadAll) {
        res = await this.$api.article.fullArticleTree(
            this.mindRootItem ? this.mindRootItem.id : undefined,
            this.isMindLocalStorage
        );
      }

      if (this.mindRootItem && this.mindRootItem.id !== tempId) return;
      if (!hasLoadAll && res.data.children) {
        this.$root.mergeData(
            res.data,
            this.mindRootItem,
            this.isMindLocalStorage
        );
        if (!res.data.id) {
          res.data.id = "root";
          res.data.title = "我的模型树";
        }
        this.mindList = res.data;

        this.edges = await this.$api.edges.getEdges(
            this.mindRootItem ? this.mindRootItem.id : undefined,
            this.isMindLocalStorage
        );

        this.loading = false;
      } else {
        // this.$root.tips("error", "当前节点没有子节点，无法生成思维导图");
        if (this.mindRootItem) {
          // this.mindList = {
          //   ...this.mindRootItem,
          // };
          this.mindList = this.mindRootItem;
        } else {
          this.mindList = {
            id: "root",
            title: "我的模型树",
          };
        }
        this.edges = await this.$api.edges.getEdges(
            this.mindRootItem ? this.mindRootItem.id : undefined,
            this.isMindLocalStorage
        );
        this.loading = false;
        return false;
      }
    },
    onMindMapChange({type, options}) {
      switch (type) {
        case MindmapEvent.focus:
          this.$root.cursorElement = options.target;
          break;
        case MindmapEvent.blur:
          this.$root.cursorElement = undefined;
          break;
        case MindmapEvent.nodeAdd:
          this.onAdd(options);
          break;
        case "paste":
          this.onPaste(options);
          break;
        case MindmapEvent.nodeSelect:
          this.onSelectedNode(options);
          break;
        case MindmapEvent.nodeTitleChange:
          this.mindMapChangeTitle(options);
          break;
        case MindmapEvent.nodeTitleBlur:
          this.mindMapTitleSave(options);
          break;
        case MindmapEvent.nodeMove:
          this.onDragEnd(options);
          break;
        case MindmapEvent.nodeDelete:
          this.mindDelete(options);
          break;
        case MindmapEvent.edgeAdd:
          this.addEdge(options);
          break;
        case MindmapEvent.edgeChange:
          this.changeEdge(options);
          break;
        case MindmapEvent.edgeDelete:
          this.edgeDelete(options);
          break;
        case MindmapEvent.edgeExist:
          this.edgeExist(options);
          break;
        case MindmapEvent.nodeChangeRoot:
          this.onChangeRoot(options);
          break;
        case MindmapEvent.nodeCorrelation:
          this.$emit("correlation", options);
          break;
        case MindmapEvent.edgeTitleChange:
          this.onChangeEdgeTitle(options);
          break;
        case MindmapEvent.nodeShowEdges:
          // console.log(options);
          this.openMapDialog(options.p);
          break;
        case MindmapEvent.loaded:
          this.loading = false;
          break;
        case MindmapEvent.edgeClickShowLabel:
          this.clickEdgeShowLabel = !this.clickEdgeShowLabel;
          this.key += 1;
          break;
        case MindmapEvent.openQuadtree: {
          const item = this.$root.getNodeById(options.id);
          this.openSameMap(item);
        }
          break;
        case MindmapEvent.changeQuadtree: {
          const idx = this.extraRoots.findIndex(r => r.id === options.oldId);
          this.extraRoots[idx] = this.$root.findItemById(options.newId);
        }
          break;
        case 'closeSameMap': {
          const idx = this.extraRoots.findIndex(r => r.id === options.id);
          if (idx !== -1) {
            this.extraRoots.splice(idx, 1);
          }
        }
          break;
        case "back":
          this.onBack();
          break;
        case "openCurMindMap":
          this.openCurMindMap();
          break;
        case "changeIconType":
          this.mindMapChangeIconType(options);
          break;
        case "aiRelatedWords":
          this.getAiAnswer(options);
          break;
        case "aiExSentence":
          this.getAiAnswer(options, "aiExSentence");
          break;
        case "aiTranslate":
          this.getAiAnswer(options, "aiTranslate");
          break;
        case "insertEditor":
          this.mindImgToEditor(options);
          break;
          // case "arrowType":
          //   console.log("---", options.dd.node);
          //   this.changeArrowType(options);
          //   break;
          // case "deleteArrow":
          //   this.deleteArrow(options);
          //   break;
      }
    },
    onChangeEdgeTitle({model, title}) {
      this.saveStatus("保存中");
      this.$emit("change-edge", {
        newItem: {
          ...model,
          title,
        },
      });
      this.saveStatus("已保存到云端");
    },
    onChangeRoot(options) {
      this.$emit("change", options);
    },
    async onAdd({node, sort}, title = "新建模型") {
      this.saveStatus('保存中');
      await this.$root
          .mindNewItem(node.getModel().id, sort, this.isMindLocalStorage, title)
          .then(async (item) => {
            if (!item) return;
            if (title === "新建模型") {
              this.mindSelect && this.mindSelect(item);
              await this.$refs.map.graph.editAddNode(node, {id: item.id, sort, isEdit: true});
            }
            this.$emit("add", {
              id: node.getModel().id,
              item: {id: item.id, sort},
            });
            // this.$refs.map.editItem();
          });
          
      this.saveStatus("已保存到云端");
    },
    onPaste(options) {
      this.mindPasteItem(options);
    },
    onSelectedNode({node}) {
      this.isOnSelectedNode = true;
      const model = node.getModel();
      if (model) {
        if (!this.selectItem || this.selectItem.id !== model.id) {
          const item = this.$root.findItemById(model.id);
          let parent = item.parent;
          while (parent) {
            parent.expand = true;
            parent = parent.parent;
          }
          this.mindSelect && this.mindSelect(item);
        }
        // if (!this.selectItem || this.selectItem.id !== model.id) {
        //   this.selectItem = this.$root.findItemById(model.id);
        //   let parent = this.selectItem.parent;
        //   while (parent) {
        //     parent.expand = true;
        //     parent = parent.parent;
        //   }
        //   this.mindSelect && this.mindSelect(this.selectItem);
        // }
      }
    },
    async mindMapChangeTitle({model, title}) {
      // if (!title) return;
      let p = this.$root.findItemById(model.id);
      if (p) {
        p.title = title;
      }
      this.$emit("changeTitle", {id: model.id, title});
    },
    async mindMapChangeIconType({model}) {
      let p = this.$root.findItemById(model.id);
      if (p) {
        await this.menuIconType({item: model});
      }
    },
    async mindMapTitleSave({model, title}) {
      if (!title) return;
      let p = this.$root.findItemById(model.id);
      if (p) {
        p.title = title;
        this.changeTitleApi && (await this.changeTitleApi(p));
      }
    },
    // 父节点发生变化时
    onDragEnd(options) {
      this.saveStatus("保存中");
      const {model, updateParams, p} = options;
      this.$root.mindMove(
          model,
          this.mindRootItem,
          this.extraRoots,
          updateParams,
          this.isMindLocalStorage
      );
      this.$emit("move", {
        parentId: updateParams.parentId,
        moveId: updateParams.articleId,
        sort: updateParams.sort,
        p,
      });
      this.saveStatus("已保存到云端");
    },
    // 右键思维导图删除
    async mindDelete(options) {
      this.saveStatus("保存中");
      const {model, deleteEdges, deleteIds} = options;
      if (model.sortId === "0") {
        this.$root.tips("error", "主模型暂不支持在思维导图模式下删除！");
        return;
      }
      if (deleteEdges && deleteEdges.length > 0) {
        await this.$root.deleteEdge(
            deleteEdges.map((r) => ({
              target: r.target,
              source: r.source,
            }))
        );
      }
      await this.deleteApi({id: model.id, deleteIds, deleteEdges});
      await this.$root.mindDeleteItem(model.id);
      this.saveStatus("已保存到云端");
    },
    addEdge(options) {
      this.saveStatus("保存中");
      this.$root.addEdge(options.data, this.isMindLocalStorage);
      this.$emit("add-edge", options);
      this.saveStatus("已保存到云端");
    },
    changeEdge(options) {
      this.saveStatus("保存中");
      let changeOptions = {
        newItem: {
          endIndex: options.edge.endIndex,
          startIndex: options.edge.startIndex,
          offset: options.edge.offset,
          source: options.edge.source,
          target: options.edge.target,
          title: options.edge.title,
          label1: options.edge.label1,
          label2: options.edge.label2,
          labelType: options.edge.labelType,
          curve: options.edge.curve,
          desc: options.edge.desc,
        },
        oldItem: {
          target: options.old.target,
          source: options.old.source,
        },
      };
      this.$root.updateEdge(changeOptions);
      this.$emit("change-edge", changeOptions);
      this.saveStatus("已保存到云端");
    },
    edgeDelete(options) {
      this.saveStatus("保存中");
      this.$root.deleteEdge([options.data]);
      this.$emit("delete-edge", options);
      this.saveStatus("已保存到云端");
    },
    edgeExist(options) {
    },
    isDoubleMap(articleId) {
      if (this.graph) {
        return this.graph.isMultiTree(articleId);
      }
      return false;
    },
    async openSameMap(item) {
      this.loading = true;
      const tempId = item.id;
      this.extraRoots.push(item);
      // const res = await this.$api.article.fullArticleTree(
      //   tempId,
      //   this.isMindLocalStorage
      // );
      // if (res.data.children) {
      //   this.$root.mergeData(res.data, item, this.isMindLocalStorage);
      // }
      // this.graph.addQuadtreeData(res.data);
      this.graph.addQuadtreeData(item);
      const edges = await this.$api.edges.getEdges(
          tempId,
          this.isMindLocalStorage
      );
      this.graph.appendEdges(edges);
      this.loading = false;
    },
  },
};
</script>

<style scoped></style>
